/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Download from '../shared/constants/downloads';
import FormMenu from '../helpers/FormMenu';

// Style
import '../page-styles/downloads.scss';
import '../page-components/downloads/index.scss';

/* -------------------------------------------------------------------------- */
/*                            All Downloads Page                              */
/* -------------------------------------------------------------------------- */

function AllDownloadsPage({ location }) {
  /* ********************************** HOOKS ******************************** */
  // Localization
  // const { t } = useTranslation();

  // Search
  const [searchInput, setSearchInput] = useState('');

  /* --------------------------------- CONST --------------------------------- */
  const getSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Téléchargements" />
      <Container>
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/${location.pathname.split('/')[1]}`}
            >
              {location.pathname.split('/')[1]}
            </Link>
          </Container>
        </Visible>
      </Container>
      <Container className="downloadsPage">
        <FormMenu getSearchInput={getSearchInput} isDate />
        <Download searchInput={searchInput} />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

AllDownloadsPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AllDownloadsPage;
